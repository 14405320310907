import { useRef, useState, useEffect, useMemo } from "react";
import { useFetcher, useNavigate } from "@remix-run/react";
import { useCombobox } from "downshift";
import debounce from "lodash/debounce";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import {
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Link,
  List,
  ListItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  InputLeftElement,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Stack,
  useDisclosure,
  ButtonGroup,
  Box,
  IconButton,
  Text,
  VisuallyHidden,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import FocusLock from "react-focus-lock";

import { SearchIcon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { IoFilter } from "react-icons/io5";

import { GoLiveDate } from "~/components/experimental/GoLiveDate";

export function FacilitySearchCombobox({
  label = "Search for a campground, backcountry site, or bus/shuttle",
  placeholder = "Type here to search...",
  initializeSearch = true,
  clearOnNavigate = false,
  defaultIsOpen = false,
  helperText = "We scan Parks Canada and Alberta/BC/Ontario Provincial parks and ACC Huts.",
  ...rest
}) {
  const bg = useColorModeValue("white", "gray.800");
  const highlightBg = useColorModeValue("brand.500", "brand.200");
  const highlightColor = useColorModeValue("white", "black");
  const border = useColorModeValue("gray.300", "gray.700");

  const navigate = useNavigate();
  const formRef = useRef(null);
  const fetcher = useFetcher();

  const [currValue, setCurrValue] = useState({ inputValue: "" });
  const debounceSetCurrValue = useRef(null);
  if (!debounceSetCurrValue.current) {
    debounceSetCurrValue.current = debounce(setCurrValue, 200);
  }

  const items = fetcher.data?.length ? fetcher.data : [];
  const isLoading = fetcher.state === "loading";
  const isInvalid = fetcher.data?.error;

  // Preload
  useEffect(() => {
    if (initializeSearch) {
      fetcher.submit(formRef.current);
    }
  }, [initializeSearch]);

  useEffect(() => {
    fetcher.submit(formRef.current);
  }, [currValue]);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getToggleButtonProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
  } = useCombobox({
    id: "FacilitySearchCombobox",
    items,
    // defaultHighlightedIndex: 0,
    defaultIsOpen,
    itemToString: (item) => item.name,
    onSelectedItemChange({ selectedItem }) {
      navigate(`/new/${selectedItem.slug}`);
      if (clearOnNavigate) {
        setInputValue("");
      }
    },
    onInputValueChange: debounceSetCurrValue.current,
  });

  const memoizedItems = useMemo(() => {
    if (!items || items.length === 0) {
      return [];
    }

    return items.map((item) => {
      const nameSegments = parse(
        item.name,
        // currValue is not included in the memo dependencies on purpose
        // i want to prevent re-rendering just client-side inputValue changes
        // if the server request is still loading
        // because it makes things jump around too much
        match(item.name, currValue.inputValue, {
          insideWords: true,
        }),
      );
      const subNameSegments = parse(
        item.subName,
        match(item.subName, currValue.inputValue, {
          insideWords: true,
        }),
      );
      return {
        item,
        nameSegments,
        subNameSegments,
      };
    });
  }, [items]);

  return (
    <Box
      pos="relative"
      {...rest}
      as={fetcher.Form}
      method="get"
      action="/api/facilities"
      autoComplete="off"
      ref={formRef}
    >
      <FormControl isInvalid={isInvalid}>
        {label ? (
          <FormLabel {...getLabelProps()} textAlign="inherit">
            {label}
          </FormLabel>
        ) : (
          <VisuallyHidden>
            <FormLabel {...getLabelProps()} textAlign="inherit">
              Search for a campground you'd like to schnerp
            </FormLabel>
          </VisuallyHidden>
        )}
        <InputGroup size="lg" mt={4}>
          <InputLeftElement
            pointerEvents="none"
            children={isLoading ? <Spinner size="sm" /> : <SearchIcon />}
          />
          <Input
            // {...getToggleButtonProps()}
            {...getInputProps()}
            name="q"
            placeholder={placeholder}
            pr="6rem"
            data-testid="facility-search-input"
          />
          <InputRightElement
            width="3rem"
            children={
              <>
                {/* <FilterPopover size="sm" fetcher={fetcher} /> */}
                <IconButton
                  size="sm"
                  {...getToggleButtonProps()}
                  icon={isOpen ? <TriangleUpIcon /> : <TriangleDownIcon />}
                />
              </>
            }
          />
        </InputGroup>
        <List
          {...getMenuProps()}
          textAlign="start"
          w="100%"
          position="absolute"
          overflowY="scroll"
          maxH="300px"
          mt="1px"
          bg={bg}
          borderBottomWidth={isOpen ? "1px" : "0px"}
          borderLeftWidth={isOpen ? "1px" : "0px"}
          borderRightWidth={isOpen ? "1px" : "0px"}
          borderRadius="md"
          borderColor={border}
        >
          {!isOpen ? null : memoizedItems.length === 0 ? (
            <ListItem px={4} py={2}>
              <Text fontSize={{ base: "sm", md: "md" }} fontStyle="italic">
                Nothing found. Try broadening your search, or{" "}
                <Link
                  isExternal
                  href="mailto:help@schnerp.com?Subject=Need%20help%20finding%20a%20campground"
                >
                  email us for help
                </Link>
              </Text>
            </ListItem>
          ) : (
            memoizedItems.map(
              ({ item, nameSegments, subNameSegments }, index) => {
                const isActive = index === highlightedIndex;
                return (
                  <ListItem
                    {...getItemProps({ item, index })}
                    key={item.id}
                    transition="background-color 220ms, color 220ms"
                    bg={isActive ? highlightBg : null}
                    color={isActive ? highlightColor : null}
                    px={4}
                    py={2}
                    cursor="pointer"
                    data-testid="facility-search-item"
                  >
                    <Text
                      fontSize={{ base: "sm", md: "md" }}
                      color={isActive ? "bg-emphasized" : "subtle"}
                    >
                      {subNameSegments.map(({ text, highlight }, index) => {
                        return (
                          <Text
                            key={index}
                            as="span"
                            fontWeight={highlight ? "bold" : "normal"}
                          >
                            {text}
                          </Text>
                        );
                      })}
                    </Text>
                    <Text fontSize={{ base: "md", md: "lg" }}>
                      {nameSegments.map(({ text, highlight }, index) => {
                        return (
                          <Text
                            key={index}
                            as="span"
                            fontWeight={highlight ? "bold" : "normal"}
                          >
                            {text}
                          </Text>
                        );
                      })}
                    </Text>
                    <GoLiveDate
                      // site={item.sites[0]}
                      color={isActive ? "bg-emphasized" : "warning"}
                    />
                  </ListItem>
                );
              },
            )
          )}
        </List>
        {helperText && <FormHelperText mt={4}>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

const FilterPopover = ({ fetcher, ...buttonProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const agencyIdRef = useRef(null);
  const agencyId = agencyIdRef?.current?.value;

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={agencyIdRef}
      onOpen={onOpen}
      onClose={onClose}
      closeOnBlur={false}
      computePositionOnMount
      placement="bottom-start"
    >
      <PopoverTrigger>
        <IconButton
          icon={<IoFilter />}
          colorScheme={agencyId ? "brand" : undefined}
          aria-label="open filter menu"
          {...buttonProps}
        />
        {/* <Button
          size="sm"
          colorScheme={agencyId ? "brand" : undefined}
          rightIcon={<Icon as={IoFilter} />}
        >
          Filter
        </Button> */}
      </PopoverTrigger>
      <PopoverContent p={4} maxW="200px">
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <FilterForm
            agencyIdRef={agencyIdRef}
            onCancel={onClose}
            fetcher={fetcher}
          />
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

const FilterForm = ({ agencyIdRef, onCancel, fetcher }) => {
  return (
    <Stack spacing={4}>
      <FormControl>
        {/* TODO - populate this from server */}
        <FormLabel htmlFor="agencyId">By agency:</FormLabel>
        <Select
          ref={agencyIdRef}
          size="sm"
          id="agencyId"
          name="agencyId"
          placeholder="Any agency"
        >
          <option value={4}>Parks Canada</option>
          <option value={1}>Alberta Parks</option>
          <option value={2}>BC Parks</option>
          <option value={3}>Ontario Parks</option>
          <option value={5}>Alpine Club of Canada (ACC)</option>
        </Select>
      </FormControl>
      <ButtonGroup d="flex" justifyContent="flex-end">
        <Button
          size="sm"
          variant="secondary"
          onClick={() => {
            agencyIdRef.current.value = "";
            fetcher.submit(agencyIdRef.current.form);
            onCancel();
          }}
        >
          Reset
        </Button>
        <Button size="sm" type="submit" onClick={onCancel}>
          Apply
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
